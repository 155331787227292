import { useState, useEffect, useContext } from "react";

import { Link, useHistory } from "react-router-dom";

import { useFetching } from "../../hooks/useFetching";
import { useFlash } from "../../hooks/useFlash";
import { AppContext } from "../../context";

import classes from './ScenariosPage.module.css';

import Sidebar from "../../components/sidebar/Sidebar";
import MainContainer from "../../components/main_container/MainContainer";
import ScenarioCard from "../../components/scenario_card/ScenarioCard";
import Button from "../../components/button/Button";
import Loader from "../../components/loader/Loader";

import ScenarioService from "../../backend/ScenarioService";

import CONFIG from "../../config";


const ScenariosPage = function() {

    const { jwtToken, setJwtToken, user, setFlashMessage, setFlashMessageType } = useContext(AppContext);

    useFlash();

    document.title = "Scenarios";

    const history = useHistory();

    const [scenarios, setScenarios] = useState([]);

    const [fetchScenarios, isScenariosLoading, scenariosError] = useFetching(async () => {

        const scenariosResult = await ScenarioService.scenarios(jwtToken, user);

        if (scenariosResult.success) {

            if (scenariosResult.token_updated) {
                setJwtToken(scenariosResult.access_token);
                localStorage.setItem('jwtToken', scenariosResult.access_token);
            }

            setScenarios(scenariosResult.scenarios);

        } else {
            
          if ('message' in scenariosResult) {
            setFlashMessage(scenariosResult.message);
          } else {
            setFlashMessage(`${scenariosResult.error.type}: ${scenariosResult.error.message}`);
          }

          setFlashMessageType('error');

        }

    });

    const [toggleScenarioActiveState, isToggleScenarioActiveLoading, toggleScenarioActiveError] = useFetching(async (scenarioId, active) => {

        let toggleScenarioActiveResult;

        if (active) {
            toggleScenarioActiveResult = await ScenarioService.enableScenario(jwtToken, user, scenarioId);
        } else {
            toggleScenarioActiveResult = await ScenarioService.disableScenario(jwtToken, user, scenarioId);
        }

        if (toggleScenarioActiveResult.success) {

            if (toggleScenarioActiveResult.token_updated) {
                setJwtToken(toggleScenarioActiveResult.access_token);
                localStorage.setItem('jwtToken', toggleScenarioActiveResult.access_token);
            }

            fetchScenarios();

        } else {

          if ('message' in toggleScenarioActiveResult) {
            setFlashMessage(toggleScenarioActiveResult.message);
          } else {
            setFlashMessage(`${toggleScenarioActiveResult.error.type}: ${toggleScenarioActiveResult.error.message}`);
          }

          setFlashMessageType('error');

        }

    });

    useEffect(() => {
        fetchScenarios();
    }, []);

    function toggleScenarioActive(scenarioId, active) {
        toggleScenarioActiveState(scenarioId, active);
    };

  return (

    <div className={classes.scenarios__wrapper}>

        <Sidebar />

        <MainContainer>

          <h1 className={classes.title}> My Scenarios </h1>

          {(isScenariosLoading || isToggleScenarioActiveLoading) && <Loader />}

          {scenarios.length > 0 ?
            <div>
              {scenarios.slice().sort((a, b) => a.id - b.id).map((scenario) => (
                <ScenarioCard
                  key={scenario.id}
                  id={scenario.id}
                  name={scenario.name}
                  description={scenario.description}
                  active={scenario.active}
                  status={scenario.status}
                  last_run={scenario.last_run}
                  onClick={() => history.push(`${CONFIG.application_slug}/scenarios/${scenario.id}`)}
                  onCheckbockChangeCallback={toggleScenarioActive}
                />
              ))}
            </div>
          :
            <div className={classes.no__scenarios__title}> No scenarios </div>
          }

          <div className={classes.create__scenario__button__wrapper}>
            <Link to={`${CONFIG.application_slug}/scenarios/new`}>
              <Button title="Create Scenario" category="success" onClick={() => {}} />
            </Link>
          </div>

        </MainContainer>
      
    </div>

  );

};

export default ScenariosPage;
