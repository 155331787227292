import classes from './Popup.module.css';


const Popup = function({ children, ...props }) {

  return (

    <div className={classes.wrapper} onClick={props.onClose}>

        <div className={classes.popup} onClick={(event) => event.stopPropagation()}>

            <div className={classes.popup__header}>

                <h2 className={classes.popup__title}> {props.title} </h2>

                <div className={classes.popup__close} onClick={props.onClose}> &times; </div>

            </div>

            <div className={classes.popup__content}>
                {children}
            </div>

        </div>

    </div>

  );

};

export default Popup;
