import { useContext } from "react";

import { useHistory } from "react-router-dom";

import { useFetching } from "../../hooks/useFetching";
import { useFlash } from "../../hooks/useFlash";
import { AppContext } from "../../context";

import classes from './RegistrationPage.module.css';

import RegAuthForm from '../../components/regauth_form/RegAuthForm';
import RegAuthFormInput from '../../components/regauth_form_input/RegAuthFormInput';
import RegAuthFormSubmit from '../../components/regauth_form_submit/RegAuthFormSubmit';
import Loader from "../../components/loader/Loader";

import RegAuthService from '../../backend/RegAuthService';

import CONFIG from "../../config";


const RegistrationPage = function () {

    document.title = "Sign Up";

    const { setJwtToken, setUser, setFlashMessage, setFlashMessageType, setFlashRedirect } = useContext(AppContext);

    useFlash();

    const history = useHistory();

    const [fetchRegistration, isLoading, error] = useFetching(async (user) => {

        const registrationResult = await RegAuthService.register(user);

        if ('message' in registrationResult) {
            setFlashMessage(registrationResult.message);
        } else {
            setFlashMessage(`${registrationResult.error.type}: ${registrationResult.error.message}`);
        }

        if (registrationResult.success) {

            setJwtToken(registrationResult.access_token);
            setUser(registrationResult.user);

            localStorage.setItem('jwtToken', registrationResult.access_token);
            localStorage.setItem('user', JSON.stringify(registrationResult.user));

            setFlashMessageType("success");
            setFlashRedirect(true);

            history.push(`${CONFIG.application_slug}/profile`);

        } else {
            setFlashMessageType("error");
        }

    });

    const handleSubmit = function (event) {

        event.preventDefault();

        const form = event.target;

        const username = form.login.value;
        const first_name = form.first_name.value;
        const last_name = form.last_name.value;
        const email = form.email.value;
        const password = form.password.value;
        const password_confirmation = form.password_confirmation.value;

        fetchRegistration({username, first_name, last_name, email, password, password_confirmation});

    };

    return (
        
        <div className={classes.wrapper}>

            <h1 className={classes.title}> Sign Up </h1>

            <div className={classes.form__container}>

                {isLoading && <Loader />}

                <RegAuthForm onSubmit={handleSubmit}>

                    <RegAuthFormInput type="text" name="login" placeholder="Username" required />
                    <RegAuthFormInput type="text" name="first_name" placeholder="First Name" />
                    <RegAuthFormInput type="text" name="last_name" placeholder="Last Name" />
                    <RegAuthFormInput type="email" name="email" placeholder="Email" required />
                    <RegAuthFormInput type="password" name="password" placeholder="Password" required />
                    <RegAuthFormInput type="password" name="password_confirmation" placeholder="Confirm Password" required />

                    <RegAuthFormSubmit title="Sign Up" />

                </RegAuthForm>

            </div>

        </div>

    );

};

export default RegistrationPage;
