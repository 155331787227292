import { useContext } from "react";

import { useHistory } from "react-router-dom";

import { useFetching } from "../../hooks/useFetching";
import { useFlash } from "../../hooks/useFlash";
import { AppContext } from "../../context";

import classes from './LoginPage.module.css';

import RegAuthForm from '../../components/regauth_form/RegAuthForm';
import RegAuthFormInput from '../../components/regauth_form_input/RegAuthFormInput';
import RegAuthFormSubmit from '../../components/regauth_form_submit/RegAuthFormSubmit';
import Loader from "../../components/loader/Loader";

import RegAuthService from '../../backend/RegAuthService';

import CONFIG from "../../config";


const LoginPage = function () {

    document.title = "Sign In";

    const { setJwtToken, setUser, setFlashMessage, setFlashMessageType, setFlashRedirect } = useContext(AppContext);

    useFlash();

    const history = useHistory();

    const [fetchLogin, isLoading, error] = useFetching(async (login, password) => {

        const loginResult = await RegAuthService.login(login, password);

        if ('message' in loginResult) {
            setFlashMessage(loginResult.message);
        } else {
            setFlashMessage(`${loginResult.error.type}: ${loginResult.error.message}`);
        }

        if (loginResult.success) {

            setJwtToken(loginResult.access_token);
            setUser(loginResult.user);

            localStorage.setItem('jwtToken', loginResult.access_token);
            localStorage.setItem('user', JSON.stringify(loginResult.user));

            setFlashMessageType("success");
            setFlashRedirect(true);

            history.push(`${CONFIG.application_slug}/profile`);

        } else {
            setFlashMessageType("error");
        }

    });

    const handleSubmit = function (event) {

        event.preventDefault();

        const form = event.target;

        const login = form.login.value;
        const password = form.password.value;

        fetchLogin({login, password});

    };

    return (
        
        <div className={classes.wrapper}>

            <h1 className={classes.title}> Sign In </h1>

            <div className={classes.form__container}>

                {isLoading && <Loader />}

                <RegAuthForm onSubmit={handleSubmit}>

                    <RegAuthFormInput type="text" name="login" placeholder="Username or Email" required />
                    <RegAuthFormInput type="password" name="password" placeholder="Password" required />

                    <RegAuthFormSubmit title="Sign In" />

                </RegAuthForm>

            </div>

        </div>

    );

};

export default LoginPage;
