import { useContext } from 'react';

import { useHistory, useLocation } from "react-router-dom";

import { useFlash } from '../../hooks/useFlash';
import { AppContext } from '../../context';

import classes from './HomePage.module.css';

import CONFIG from '../../config';


const HomePage = function () {

    const { jwtToken, user, isLoading } = useContext(AppContext);

    const history = useHistory();

    const location = useLocation();

    const params = new URLSearchParams(location.search);

    if (params.has('p'))
        history.push(`${CONFIG.application_slug}/${params.get('p')}`);
    else if (jwtToken && user)
        history.push(`${CONFIG.application_slug}/profile`);

    useFlash();

    document.title = "Flexible Automation Tool";

    return (

        <div>
            <h1 className={classes.title}> Flexible Automation Tool </h1>
        </div>

    );

};

export default HomePage;
