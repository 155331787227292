import classes from './ProfileFormInput.module.css';


const ProfileFormInput = function ({...props}) {

    return (
        <div className={classes.profile__form__input__wrapper}>
            <label htmlFor={props.name} className={classes.profile__form__input__label}> {props.placeholder} </label>
            <input className={classes.profile__form__input} {...props} />
        </div>
    )

};

export default ProfileFormInput;
