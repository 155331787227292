import classes from './ProfileFormSubmit.module.css';


const ProfileFormSubmit = function ({...props}) {

    return (
        <button type="submit" className={classes.profile__form_submit}> {props.title} </button>
    );

};

export default ProfileFormSubmit;
