import classes from './Flash.module.css';


const Flash = function(props) {

    const flashClasses = [classes.flash, classes[`flash__${props.type}`]].join(' ');

  return (
    <div className={flashClasses}> {props.message} </div>
  );

};

export default Flash;
