import classes from './ScenarioFormTextarea.module.css';


const ScenarioFormTextarea = function (props) {

  return (
        <textarea className={classes.scenario__form__textarea} {...props}>{props.defaut}</textarea>
  );

};

export default ScenarioFormTextarea;
