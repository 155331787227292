import classes from './Button.module.css';


const Button = function ({...props}) {

    let buttonClasses;

    if ('category' in props) {
        buttonClasses = [classes.button, classes[props.category]].join(' ');
    } else {
        buttonClasses = classes.button;
    }

    return (
        <button className={buttonClasses} {...props}> {props.title} </button>
    );

};

export default Button;
