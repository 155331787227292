import classes from './RegAuthFormInput.module.css';


const RegAuthFormInput = function ({...props}) {

    return (
        <input className={classes.regauth__form__input} {...props} />
    )

};

export default RegAuthFormInput;
