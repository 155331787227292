import classes from './RegAuthForm.module.css';


const RegAuthForm = function ({children, ...props}) {

  return (
    <form className={classes.regauth__form} {...props}> {children} </form>
  );

};

export default RegAuthForm;
