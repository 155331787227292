import classes from './MainContainer.module.css';


const MainContainer = function({ children }) {

  return (

    <div className={classes.main__container}>
      {children}
    </div>

  );

};

export default MainContainer;
