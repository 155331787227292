import { useContext } from "react";

import { Switch, Route } from "react-router-dom";

import { AppContext } from "../context";

import { publicRoutes, privateRoutes } from "../routes";

import Loader from "./loader/Loader";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import CONFIG from "../config";


const AppRouter = function () {


  const { jwtToken, user, isLoading } = useContext(AppContext);

  if (isLoading) return <Loader />;

  return (

    <Switch>

      {jwtToken && user ? privateRoutes.map(route =>
        <Route
          key={route.path}
          component={route.component}
          path={route.path}
          exact={route.exact}
        />
      ) : publicRoutes.map(route =>
        <Route
          key={route.path}
          component={route.component}
          path={route.path}
          exact={route.exact}
        />
      )}

      {jwtToken && user ? <Redirect to={`${CONFIG.application_slug}/profile`} /> : <Redirect to={CONFIG.application_slug === "" ? "/" : CONFIG.application_slug} />}

    </Switch>

  );

};

export default AppRouter;
