import HomePage from '../pages/home/HomePage';
import LoginPage from '../pages/login/LoginPage';
import RegistrationPage from '../pages/registration/RegistrationPage';
import ProfilePage from '../pages/profile/ProfilePage';
import ScenariosPage from '../pages/scenarios/ScenariosPage';
import ScenarioPage from '../pages/scenario/ScenarioPage';
import NewScenarioPage from '../pages/new_scenario/NewScenarioPage';

import CONFIG from '../config';


export const publicRoutes = [
    {path: CONFIG.application_slug === "" ? "/" : CONFIG.application_slug, component: HomePage, exact: true},
    {path: `${CONFIG.application_slug}/login`, component: LoginPage, exact: true},
    {path: `${CONFIG.application_slug}/register`, component: RegistrationPage, exact: true}
];

export const privateRoutes = [
    {path: CONFIG.application_slug === "" ? "/" : CONFIG.application_slug, component: HomePage, exact: true},
    {path: `${CONFIG.application_slug}/profile`, component: ProfilePage, exact: true},
    {path: `${CONFIG.application_slug}/scenarios`, component: ScenariosPage, exact: true},
    {path: `${CONFIG.application_slug}/scenarios/new`, component: NewScenarioPage, exact: true},
    {path: `${CONFIG.application_slug}/scenarios/:id`, component: ScenarioPage, exact: true}
];
