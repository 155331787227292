import classes from './RegAuthFormSubmit.module.css';


const RegAuthFormSubmit = function ({...props}) {

    return (
        <button type="submit" className={classes.regauth__form_submit}> {props.title} </button>
    );

};

export default RegAuthFormSubmit;
