import { useContext } from "react";

import { Link, useLocation, useHistory } from "react-router-dom";

import { AppContext } from "../../context";

import classes from './Navbar.module.css';

import logo from '../../assets/img/logo.png';

import CONFIG from "../../config";


const Navbar = function () {

    const { jwtToken, setJwtToken, user, setUser } = useContext(AppContext);

    const location = useLocation();

    const history = useHistory();

    function logout() {

        localStorage.removeItem('jwtToken');
        localStorage.removeItem('user');

        setJwtToken(null);
        setUser(null);

        history.push(`${CONFIG.application_slug}/login`);

    }

    let firstNavbarLinkClasses = [classes.navbar__link, classes.navbar__link__first];
    let activeNavbarLinkClasses = [classes.navbar__link, classes.navbar__link__active];
    
    const logoutButtonClasses = [classes.navbar__link, classes.navbar__link__logout].join(' ');

    let activePathname;

    if (jwtToken && user) activePathname = `${CONFIG.application_slug}/profile`;
    else activePathname = `${CONFIG.application_slug}/login`;

    if (location.pathname === activePathname) {
        firstNavbarLinkClasses.push(classes.navbar__link__active);
    }

    firstNavbarLinkClasses = firstNavbarLinkClasses.join(' ');
    activeNavbarLinkClasses = activeNavbarLinkClasses.join(' ');

    let profileLinkText;

    if (jwtToken && user) {

        if (user.first_name && user.last_name) {
            profileLinkText = `${user.first_name} ${user.last_name}`;
        } else if (user.first_name) {
            profileLinkText = user.first_name;
        } else if (user.last_name) {
            profileLinkText = user.last_name;
        } else {
            profileLinkText = `@${user.username}`
        }

    }

    return (

        <div className={classes.navbar}>

            <div className={classes.navbar__logo}>
                <Link to={CONFIG.application_slug === "" ? "/" : CONFIG.application_slug}> <img src={logo} alt="Flexible Automation Tool Logo" /> </Link>
            </div>

            {(jwtToken && user) ?
                
                <div className={classes.navbar__links}>

                    <Link className={firstNavbarLinkClasses} to={`${CONFIG.application_slug}/profile`}> {profileLinkText} </Link>
                    <button className={logoutButtonClasses} onClick={logout}> Logout </button>

                </div>

            :

                <div className={classes.navbar__links}>

                    <Link className={firstNavbarLinkClasses} to={`${CONFIG.application_slug}/login`}> Sign In </Link>
                    <Link className={location.pathname === `${CONFIG.application_slug}/register` ? activeNavbarLinkClasses : classes.navbar__link} to={`${CONFIG.application_slug}/register`}> Sign Up </Link>

                </div>

            }

        </div>

    );

};

export default Navbar;
