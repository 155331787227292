import { useContext } from "react";

import { useHistory } from "react-router-dom";

import { useFetching } from "../../hooks/useFetching";
import { useFlash } from "../../hooks/useFlash";
import { AppContext } from "../../context";

import classes from './ProfilePage.module.css';

import Sidebar from "../../components/sidebar/Sidebar";
import MainContainer from "../../components/main_container/MainContainer";
import ProfileForm from "../../components/profile_form/ProfileForm";
import ProfileFormInput from "../../components/profile_form_input/ProfileFormInput";
import ProfileFormSubmit from "../../components/profile_form_submit/ProfileFormSubmit";
import Button from "../../components/button/Button";
import Popup from "../../components/popup/Popup";
import Loader from "../../components/loader/Loader";

import ProfileService from "../../backend/ProfileService";

import CONFIG from "../../config";


const ProfilePage = function() {

    const { jwtToken, setJwtToken, user, setUser, setFlashMessage, setFlashMessageType, setFlashRedirect, isPopupOpen, setIsPopupOpen } = useContext(AppContext);

    if (user.first_name && user.last_name) {
        document.title = `${user.first_name} ${user.last_name}`;
    } else if (user.first_name) {
        document.title = user.first_name;
    } else if (user.last_name) {
        document.title = user.last_name;
    } else {
        document.title = user.username;
    }

    useFlash();

    const history = useHistory();

    const [fetchEditProfile, isEditProfileLoading, editProfileError] = useFetching(async (user) => {

        const editProfileResult = await ProfileService.edit(jwtToken, user);

        if ('message' in editProfileResult) {
            setFlashMessage(editProfileResult.message);
        } else {
            setFlashMessage(`${editProfileResult.error.type}: ${editProfileResult.error.message}`);
        }

        if (editProfileResult.success) {

            setUser(editProfileResult.user);

            localStorage.setItem('user', JSON.stringify(editProfileResult.user));

            if (editProfileResult.token_updated) {
                setJwtToken(editProfileResult.access_token);
                localStorage.setItem('jwtToken', editProfileResult.access_token);
            }

            setFlashMessageType("success");

        } else {
            setFlashMessageType("error");
        }

    });

    const [fetchChangePassword, isChangePasswordLoading, changePasswordError] = useFetching(async (old_password, new_password, confirm_password) => {

        const changePasswordResult = await ProfileService.changePassword(jwtToken, user, {'old': old_password, 'new': new_password, 'confirm': confirm_password});

        if ('message' in changePasswordResult) {
            setFlashMessage(changePasswordResult.message);
        } else {
            setFlashMessage(`${changePasswordResult.error.type}: ${changePasswordResult.error.message}`);
        }

        if (changePasswordResult.success) {

            if (changePasswordResult.token_updated) {
                setJwtToken(changePasswordResult.access_token);
                localStorage.setItem('jwtToken', changePasswordResult.access_token);
            }

            setFlashMessageType("success");

        } else {
            setFlashMessageType("error");
        }

    });

    const [fetchDeleteAccount, isDeleteAccountLoading, deleteAccountError] = useFetching(async () => {

        setIsPopupOpen(false);

        const deleteAccountResult = await ProfileService.delete(jwtToken, user);

        if ('message' in deleteAccountResult) {
            setFlashMessage(deleteAccountResult.message);
        } else {
            setFlashMessage(`${deleteAccountResult.error.type}: ${deleteAccountResult.error.message}`);
        }

        if (deleteAccountResult.success) {

            setUser(null);
            setJwtToken(null);

            localStorage.removeItem('user');
            localStorage.removeItem('jwtToken');

            setFlashMessageType("success");
            setFlashRedirect(true);

            history.push(CONFIG.application_slug === "" ? "/" : CONFIG.application_slug);

        } else {
            setFlashMessageType("error");
        }

    });

    const handleEditProfileSubmit = function (event) {

        event.preventDefault();

        const form = event.target;

        const id = user.id;

        const first_name = form.first_name.value;
        const last_name = form.last_name.value;
        const email = form.email.value;

        fetchEditProfile({id, first_name, last_name, email});

    };

    const handleChangePasswordSubmit = function (event) {

        event.preventDefault();

        const form = event.target;

        const old_password = form.old_password.value;
        const new_password = form.new_password.value;
        const confirm_password = form.confirm_password.value;

        fetchChangePassword(old_password, new_password, confirm_password);

    };

    const handleDeleteAccountClick = function () {

        setIsPopupOpen(true);

    };

  return (

    <div className={classes.profile__wrapper}>

        {(isEditProfileLoading || isChangePasswordLoading || isDeleteAccountLoading) && <Loader />}

        {isPopupOpen &&
            <Popup title="Delete Confirmation" onClose={() => setIsPopupOpen(false)}>

                <div className={classes.popup__wrapper}>

                    <p className={classes.popup__text}> Are you sure you want to delete your account? </p>

                    <div className={classes.popup__buttons}>

                        <Button title="No" category="danger" onClick={() => setIsPopupOpen(false)} />
                        <Button title="Yes" category="success" onClick={fetchDeleteAccount} />

                    </div>

                </div>

            </Popup>
        }

        <Sidebar />

        <MainContainer>

            <h1 className={classes.title}> My Profile </h1>

            <h2 className={classes.subtitle}> Edit Profile </h2>

            <ProfileForm onSubmit={handleEditProfileSubmit}>

                <div className={classes.profile__form_inputs}>

                    <ProfileFormInput name="first_name" placeholder="First Name" defaultValue={user.first_name} />
                    <ProfileFormInput name="last_name" placeholder="Last Name" defaultValue={user.last_name} />
                    <ProfileFormInput name="email" placeholder="Email" defaultValue={user.email} required />

                </div>

                <ProfileFormSubmit title="Save Changes" />

            </ProfileForm>

            <h2 className={classes.subtitle}> Change Password </h2>

            <ProfileForm onSubmit={handleChangePasswordSubmit}>

                <div className={classes.profile__form_inputs}>

                    <ProfileFormInput name="old_password" placeholder="Old Password" type="password" required />
                    <ProfileFormInput name="new_password" placeholder="New Password" type="password" required />
                    <ProfileFormInput name="confirm_password" placeholder="Confirm Password" type="password" required />

                </div>

                <ProfileFormSubmit title="Change Password" />

            </ProfileForm>

            <Button title="Delete Account" category="danger" onClick={handleDeleteAccountClick} />

        </MainContainer>
      
    </div>

  );

};

export default ProfilePage;
