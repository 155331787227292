import axios from "axios";

import CONFIG from "../config";


export default class RegAuthService {

    static async edit(jwtToken, user) {

        try {

            const url = `${CONFIG.backend_url}/userprofile/edit`;

            const response = await axios.put(url, {user}, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });

            return response.data;

        } catch (error) {
            
            if (error.response) {
                return error.response.data;
            } else {
                throw error;
            };

        };

    };

    static async changePassword(jwtToken, user, password) {

        try {

            const url = `${CONFIG.backend_url}/userprofile/change-password`;

            const response = await axios.put(url, {'user': user, 'password': password}, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });

            return response.data;

        } catch (error) {
            
            if (error.response) {
                return error.response.data;
            } else {
                throw error;
            };

        };

    };

    static async delete(jwtToken, user) {

        try {

            const url = `${CONFIG.backend_url}/userprofile/delete`;

            const response = await axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                },
                data: {'user': user}
            });

            return response.data;

        } catch (error) {
            
            if (error.response) {
                return error.response.data;
            } else {
                throw error;
            };

        };

    };

};
