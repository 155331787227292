import classes from './ScenarioForm.module.css';


const ScenarioForm = function ({children, ...props}) {

  return (
    <form className={classes.scenario__form} {...props}> {children} </form>
  );

};

export default ScenarioForm;
