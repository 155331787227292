import { useState, useEffect, useContext } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import * as go from "gojs";

import { initExisting } from '../../diagram'

import { useFetching } from "../../hooks/useFetching";
import { useFlash } from "../../hooks/useFlash";
import { AppContext } from "../../context";

import classes from './ScenarioPage.module.css';

import ScenarioForm from '../../components/scenario_form/ScenarioForm';
import ScenarioFormInput from '../../components/scenario_form_input/ScenarioFormInput';
import ScenarioFormTextarea from '../../components/scenario_form_textarea/ScenarioFormTextarea';
import Button from '../../components/button/Button';
import Popup from '../../components/popup/Popup';
import Loader from '../../components/loader/Loader';

import ScenarioService from "../../backend/ScenarioService";

import CONFIG from "../../config";

import time_trigger from "../../assets/img/node/time_trigger.png";
import webhook from "../../assets/img/node/webhook.png";
import condition from "../../assets/img/node/condition.png";
import make_http_request from "../../assets/img/node/make_http_request.png";
import send_message from "../../assets/img/node/send_message.png";
import send_email from "../../assets/img/node/send_email.png";
import openweathermap from "../../assets/img/node/openweathermap.png";
import server_monitor from "../../assets/img/node/server_monitor.png";
import end from "../../assets/img/node/end.png";


const ScenarioPage = function() {

    const $ = go.GraphObject.make;

    const allPopupsClosed = {
        time_trigger: false,
        webhook: false,
        condition: false,
        make_http_request: false,
        send_message: false,
        send_email: false,
        openweathermap: false
    };

    const { jwtToken, setJwtToken, user, setFlashMessage, setFlashMessageType, setFlashRedirect, isPopupOpen, setIsPopupOpen } = useContext(AppContext);

    const params = useParams();

    const history = useHistory();

    useFlash();

    const [isNodePopupsOpen, setIsNodePopupsOpen] = useState(allPopupsClosed);

    const [popupCallback, setPopupCallback] = useState({callback: null});

    const [popupCurrentNodeId, setPopupCurrentNodeId] = useState(null);

    const [scenarioName, setScenarioName] = useState(null);
    const [scenarioDescription, setScenarioDescription] = useState(null);

    const [scenarioDiagram, setScenarioDiagram] = useState(null);

    const [fetchScenario, isScenarioLoading, scenarioError] = useFetching(async (name, description) => {

        let diagramNodeDataArray = JSON.parse(JSON.stringify(scenarioDiagram.model.nodeDataArray));
        let diagramLinkDataArray = [];

        for (let i = 0; i < diagramNodeDataArray.length; i++) {

            delete diagramNodeDataArray[i].image;
            delete diagramNodeDataArray[i].background;
            delete diagramNodeDataArray[i].__gohashid;

            diagramNodeDataArray[i].id = diagramNodeDataArray[i].key;

            delete diagramNodeDataArray[i].key;
            
            let locationSplitted = diagramNodeDataArray[i].location.split(' ');

            diagramNodeDataArray[i].location = {
                x: Number(locationSplitted[0]),
                y: Number(locationSplitted[1])
            };

        }

        for (let i = 0; i < scenarioDiagram.model.linkDataArray.length; i++) {

            diagramLinkDataArray.push({
                from: scenarioDiagram.model.linkDataArray[i].from,
                to: scenarioDiagram.model.linkDataArray[i].to,
                condition: scenarioDiagram.model.linkDataArray[i].condition
            });

        }

        const createScenarioResult = await ScenarioService.editScenario(jwtToken, user, params.id, {
            name: name,
            description: description,
            diagram: {
                nodes: diagramNodeDataArray,
                links: diagramLinkDataArray
            }
        });

        if (createScenarioResult.success) {
            
            setFlashMessage(createScenarioResult.message);

            setFlashMessageType('success');

            setFlashRedirect(true);

            history.push(`${CONFIG.application_slug}/scenarios`);

        } else {
            
          if ('message' in createScenarioResult) {
            setFlashMessage(createScenarioResult.message);
          } else {
            setFlashMessage(`${createScenarioResult.error.type}: ${createScenarioResult.error.message}`);
          }

          setFlashMessageType('error');

        }

    });

    const [fetchScenarioInitial, isScenarioInitialLoading, scenarioInitialError] = useFetching(async () => {

        const getScenarioResult = await ScenarioService.scenario(jwtToken, user, params.id);

        if (getScenarioResult.success) {

            const scenario = getScenarioResult.scenario;

            document.title = scenario.name;

            if (getScenarioResult.token_updated) {
                setJwtToken(getScenarioResult.access_token);
                localStorage.setItem('jwtToken', getScenarioResult.access_token);
            }

            setScenarioName(scenario.name);
            setScenarioDescription(scenario.description);

            let nodes = [];
            let links = [];

            const images = { time_trigger, webhook, condition, make_http_request, send_message, send_email, openweathermap, server_monitor, end };
            
            const backgrounds = {
                time_trigger: "#00bfff",
                webhook: "#ff5858",
                condition: "#ff3c00",
                make_http_request: "#a9c9f0",
                send_message: "#58eafa",
                send_email: "#f0a9f0",
                openweathermap: "#fab8b8",
                server_monitor: "#b8b8ff",
                end: "#ff3c00"
            };

            for (let i = 0; i < Object.values(scenario.diagram.nodes).length; i++) {

                const node = Object.values(scenario.diagram.nodes)[i];

                let nodeData = {
                    key: node.id,
                    category: node.category,
                    type: node.type,
                    image: images[node.type],
                    background: backgrounds[node.type],
                    location: `${node.location.x} ${node.location.y}`
                };

                for (let j = 0; j < Object.keys(node.parameters).length; j++) {

                    const parameter = Object.keys(node.parameters)[j];

                    nodeData[parameter] = node.parameters[parameter];

                }

                nodes.push(nodeData);

            }

            for (let i = 0; i < scenario.diagram.links.length; i++) {

                const link = scenario.diagram.links[i];

                if (scenario.diagram.nodes[link.from].type === 'condition') {

                    links.push({
                        from: link.from,
                        to: link.to,
                        condition: link.condition,
                        label: link.condition ? "Yes" : "No"
                    });

                } else {

                    links.push({
                        from: link.from,
                        to: link.to,
                        condition: link.condition
                    });

                }

            }
    
            initExisting($, setIsNodePopupsOpen, setPopupCallback, setScenarioDiagram, setPopupCurrentNodeId, nodes, links);

        } else {

            if ('message' in getScenarioResult) {
                setFlashMessage(getScenarioResult.message);
            } else {
                setFlashMessage(`${getScenarioResult.error.type}: ${getScenarioResult.error.message}`);
            }
    
            setFlashMessageType('error');
    
            }

    });

    const [fetchDeleteScenario, isDeleteScenarioLoading, deleteScenarioError] = useFetching(async () => {

        setIsPopupOpen(false);

        const deleteScenarioResult = await ScenarioService.deleteScenario(jwtToken, user, params.id);

        if (deleteScenarioResult.success) {
                
            if (deleteScenarioResult.token_updated) {
                setJwtToken(deleteScenarioResult.access_token);
                localStorage.setItem('jwtToken', deleteScenarioResult.access_token);
            }
    
            setFlashMessage(deleteScenarioResult.message);
    
            setFlashMessageType('success');
    
            setFlashRedirect(true);
    
            history.push(`${CONFIG.application_slug}/scenarios`);
    
        } else {

            if ('message' in deleteScenarioResult) {
                setFlashMessage(deleteScenarioResult.message);
            } else {
                setFlashMessage(`${deleteScenarioResult.error.type}: ${deleteScenarioResult.error.message}`);
            }
        
            setFlashMessageType('error');
        
        }

    });

    useEffect(function () {

        fetchScenarioInitial();

        window.addEventListener('keydown', function (event) {
            if (event.key === 'Escape') {
                setIsNodePopupsOpen(allPopupsClosed);
            }
        });

    }, []);

    function findNodeParameter(nodeId, parameter) {

        const node = scenarioDiagram.findNodeForKey(nodeId);

        if (node === null) {
            return null;
        }

        const nodeData = node.data;

        if (parameter in nodeData) {
            return nodeData[parameter];
        } else {
            return null;
        }

    }

    return (
        
        <div>

            {(isScenarioLoading || isScenarioInitialLoading) && <Loader />}

            {isNodePopupsOpen.time_trigger &&
                <Popup title="Time Trigger" onClose={() => {
                    setIsNodePopupsOpen(allPopupsClosed);
                }}>

                    <form className={classes.popup__form} onSubmit={(event) => {

                        event.preventDefault();

                        const interval = event.target.interval.value;
                        const time_unit = event.target.time_unit.value;

                        popupCallback.callback({interval, time_unit});

                        setIsNodePopupsOpen(allPopupsClosed);
                    
                    }}>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="time_trigger__interval"> Interval </label>
                            <input className={classes.popup__input} type="number" min={0} name="interval" id="time_trigger__interval" placeholder="Interval" defaultValue={findNodeParameter(popupCurrentNodeId, 'interval') !== null ? findNodeParameter(popupCurrentNodeId, 'interval') : ''} required />
                        </div>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="time_trigger__time_unit"> Time Unit </label>
                            <select className={classes.popup__select} name="time_unit" id="time_trigger__time_unit" defaultValue={findNodeParameter(popupCurrentNodeId, 'time_unit') !== null ? findNodeParameter(popupCurrentNodeId, 'time_unit') : 'second'}>
                                <option value="second"> Seconds </option>
                                <option value="minute"> Minutes </option>
                                <option value="hour"> Hours </option>
                            </select>
                        </div>

                        <div className={classes.buttons__wrapper}>
                            <Button category="danger" title="Cancel" onClick={() => {setIsNodePopupsOpen(allPopupsClosed);}} />
                            <Button type="submit" category="success" title="Save" />
                        </div>

                    </form>

                </Popup>
            }

            {isNodePopupsOpen.webhook &&
                <Popup title="Webhook" onClose={() => {
                    setIsNodePopupsOpen(allPopupsClosed);
                }}>
                    
                    <p className={classes.popup__text}>
                         <b> URL: </b> {`${CONFIG.backend_url}/webhook/${popupCallback.callback({}).key}`}
                    </p>

                </Popup>
            }

            {isNodePopupsOpen.condition &&
                <Popup title="Condition" onClose={() => {
                    setIsNodePopupsOpen(allPopupsClosed);
                }}>
                    
                    <form className={classes.popup__form} onSubmit={(event) => {

                        event.preventDefault();

                        const condition = event.target.condition.value;

                        popupCallback.callback({condition});

                        setIsNodePopupsOpen(allPopupsClosed);

                    }}>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="condition__condition"> Condition </label>
                            <textarea className={classes.popup__textarea} name="condition" id="condition__condition" placeholder="Condition" required>{findNodeParameter(popupCurrentNodeId, 'condition') !== null ? findNodeParameter(popupCurrentNodeId, 'condition') : ''}</textarea>
                        </div>

                        <div className={classes.buttons__wrapper}>
                            <Button category="danger" title="Cancel" onClick={() => {setIsNodePopupsOpen(allPopupsClosed);}} />
                            <Button type="submit" category="success" title="Save" />
                        </div>

                    </form>

                </Popup>
            }

            {isNodePopupsOpen.make_http_request &&
                <Popup title="Make HTTP Request" onClose={() => {
                    setIsNodePopupsOpen(allPopupsClosed);
                }}>
                    
                    <form className={classes.popup__form} onSubmit={(event) => {

                        event.preventDefault();

                        const url = event.target.url.value;
                        const method = event.target.method.value;
                        const authorization = event.target.authorization.value;
                        const json = event.target.json.value;

                        popupCallback.callback({url, method, authorization, json});

                        setIsNodePopupsOpen(allPopupsClosed);

                    }}>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="make_http_request__url"> URL </label>
                            <input className={classes.popup__input} type="text" name="url" id="make_http_request__url" placeholder="URL" required defaultValue={findNodeParameter(popupCurrentNodeId, 'url') !== null ? findNodeParameter(popupCurrentNodeId, 'url') : ''} />
                        </div>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="make_http_request__method"> Method </label>
                            <select className={classes.popup__select} name="method" id="make_http_request__method" defaultValue={findNodeParameter(popupCurrentNodeId, 'method') !== null ? findNodeParameter(popupCurrentNodeId, 'method') : 'get'}>
                                <option value="get"> GET </option>
                                <option value="post"> POST </option>
                                <option value="put"> PUT </option>
                                <option value="delete"> DELETE </option>
                            </select>
                        </div>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="make_http_request__authorization"> Authorization </label>
                            <input className={classes.popup__input} type="text" name="authorization" id="make_http_request__authorization" placeholder="Authorization" defaultValue={findNodeParameter(popupCurrentNodeId, 'authorization') !== null ? findNodeParameter(popupCurrentNodeId, 'authorization') : ''} />
                        </div>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="make_http_request__json"> JSON </label>
                            <textarea className={classes.popup__textarea} name="json" id="make_http_request__json" placeholder="JSON">{findNodeParameter(popupCurrentNodeId, 'json') !== null ? findNodeParameter(popupCurrentNodeId, 'json') : ''}</textarea>
                        </div>

                        <div className={classes.buttons__wrapper}>
                            <Button category="danger" title="Cancel" onClick={() => {setIsNodePopupsOpen(allPopupsClosed);}} />
                            <Button type="submit" category="success" title="Save" />
                        </div>

                    </form>

                </Popup>
            }

            {isNodePopupsOpen.send_message &&
                <Popup title="Send Message" onClose={() => {
                    setIsNodePopupsOpen(allPopupsClosed);
                }}>
                    
                    <form className={classes.popup__form} onSubmit={(event) => {

                        event.preventDefault();

                        const messenger = event.target.messenger.value;
                        const receiver = event.target.receiver.value;
                        const message = event.target.message.value;

                        popupCallback.callback({messenger, receiver, message});

                        setIsNodePopupsOpen(allPopupsClosed);

                    }}>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="send_message__messenger"> Messenger </label>
                            <select className={classes.popup__select} name="messenger" id="send_message__messenger" defaultValue={findNodeParameter(popupCurrentNodeId, 'messenger') !== null ? findNodeParameter(popupCurrentNodeId, 'messenger') : 'telegram'}>
                                <option value="telegram"> Telegram </option>
                                <option value="viber"> Viber </option>
                            </select>
                        </div>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="send_message__receiver"> Receiver </label>
                            <input className={classes.popup__input} type="text" name="receiver" id="send_message__receiver" placeholder="Receiver" required defaultValue={findNodeParameter(popupCurrentNodeId, 'receiver') !== null ? findNodeParameter(popupCurrentNodeId, 'receiver') : ''} />
                        </div>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="send_message__message"> Message </label>
                            <textarea className={classes.popup__textarea} name="message" id="send_message__message" placeholder="Message" required>{findNodeParameter(popupCurrentNodeId, 'message') !== null ? findNodeParameter(popupCurrentNodeId, 'message') : ''}</textarea>
                        </div>

                        <div className={classes.buttons__wrapper}>
                            <Button category="danger" title="Cancel" onClick={() => {setIsNodePopupsOpen(allPopupsClosed);}} />
                            <Button type="submit" category="success" title="Save" />
                        </div>

                    </form>

                </Popup>
            }

            {isNodePopupsOpen.send_email &&
                <Popup title="Send Email" onClose={() => {
                    setIsNodePopupsOpen(allPopupsClosed);
                }}>
                    
                    <form className={classes.popup__form} onSubmit={(event) => {

                        event.preventDefault();

                        const receiver = event.target.receiver.value;
                        const message = event.target.message.value;

                        popupCallback.callback({receiver, message});

                        setIsNodePopupsOpen(allPopupsClosed);

                    }}>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="send_email__receiver"> Receiver </label>
                            <input className={classes.popup__input} type="text" name="receiver" id="send_email__receiver" placeholder="Receiver" required defaultValue={findNodeParameter(popupCurrentNodeId, 'receiver') !== null ? findNodeParameter(popupCurrentNodeId, 'receiver') : ''} />
                        </div>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="send_email__message"> Message </label>
                            <textarea className={classes.popup__textarea} name="message" id="send_email__message" placeholder="Message" required>{findNodeParameter(popupCurrentNodeId, 'message') !== null ? findNodeParameter(popupCurrentNodeId, 'message') : ''}</textarea>
                        </div>

                        <div className={classes.buttons__wrapper}>
                            <Button category="danger" title="Cancel" onClick={() => {setIsNodePopupsOpen(allPopupsClosed);}} />
                            <Button type="submit" category="success" title="Save" />
                        </div>

                    </form>

                </Popup>
            }

            {isNodePopupsOpen.openweathermap &&
                <Popup title="OpenWeatherMap" onClose={() => {
                    setIsNodePopupsOpen(allPopupsClosed);
                }}>
                    
                    <form className={classes.popup__form} onSubmit={(event) => {

                        event.preventDefault();

                        const api_key = event.target.api_key.value;
                        const city = event.target.city.value;
                        const unit = event.target.unit.value;

                        popupCallback.callback({api_key, city, unit});

                        setIsNodePopupsOpen(allPopupsClosed);

                    }}>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="openweathermap__api_key"> API Key </label>
                            <input className={classes.popup__input} type="text" name="api_key" id="openweathermap__api_key" placeholder="API Key" required defaultValue={findNodeParameter(popupCurrentNodeId, 'api_key') !== null ? findNodeParameter(popupCurrentNodeId, 'api_key') : ''} />
                        </div>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="openweathermap__city"> City </label>
                            <input className={classes.popup__input} type="text" name="city" id="openweathermap__city" placeholder="City" required defaultValue={findNodeParameter(popupCurrentNodeId, 'city') !== null ? findNodeParameter(popupCurrentNodeId, 'city') : ''} />
                        </div>

                        <div className={classes.popup__input__label__wrapper}>
                            <label className={classes.popup__input__label} htmlFor="openweathermap__unit"> Unit </label>
                            <select className={classes.popup__select} name="unit" id="openweathermap__unit" defaultValue={findNodeParameter(popupCurrentNodeId, 'unit') !== null ? findNodeParameter(popupCurrentNodeId, 'unit') : 'standard'}>
                                <option value="standard"> Standard </option>
                                <option value="metric"> Metric </option>
                                <option value="imperial"> Imperial </option>
                            </select>
                        </div>

                        <div className={classes.buttons__wrapper}>
                            <Button category="danger" title="Cancel" onClick={() => {setIsNodePopupsOpen(allPopupsClosed);}} />
                            <Button type="submit" category="success" title="Save" />
                        </div>

                    </form>

                </Popup>
            }

            {isPopupOpen &&
                <Popup title="Delete Confirmation" onClose={() => setIsPopupOpen(false)}>

                    <div className={classes.delete__popup__wrapper}>

                        <p className={classes.delete__popup__text}> Are you sure you want to delete your scenario? </p>

                        <div className={classes.delete__popup__buttons}>

                            <Button title="No" category="danger" onClick={() => setIsPopupOpen(false)} />
                            <Button title="Yes" category="success" onClick={fetchDeleteScenario} />

                        </div>

                    </div>

                </Popup>
            }

            <h1 className={classes.title}> {scenarioName} </h1>

            <div className={classes.scenario__wrapper}>

                <div className={classes.sidebar}>

                    <ScenarioForm onSubmit={(event) => {

                        event.preventDefault();

                        const name = event.target.name.value;
                        const description = event.target.description.value;

                        fetchScenario(name, description);

                    }}>

                        <ScenarioFormInput type="text" name="name" placeholder="Scenario Name" required defaultValue={scenarioName !== null ? scenarioName : ''} />

                        <ScenarioFormTextarea name="description" placeholder="Scenario Description" defaultValue={scenarioDescription !== null ? scenarioDescription : ''} />

                        <div className={classes.scenario__form__button__wrapper}>
                            <Button type="submit" category="success" title="Save" />
                        </div>

                    </ScenarioForm>

                    <div className={classes.delete__button__wrapper}>
                        <Button category="danger" title="Delete" onClick={() => {setIsPopupOpen(true);}} />
                    </div>

                    <div className={classes.palette} id="palette">

                    </div>

                </div>

                <div className={classes.diagram} id="diagram">

                </div>

            </div>

        </div>

    );

};

export default ScenarioPage;
