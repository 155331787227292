import classes from './ScenarioFormInput.module.css';


const ScenarioFormInput = function ({...props}) {

    return (
        <input className={classes.scenario__form__input} {...props} />
    )

};

export default ScenarioFormInput;
