import { useState, useEffect } from "react";

import { BrowserRouter } from "react-router-dom";

import Navbar from "./components/navbar/Navbar";
import AppRouter from "./components/AppRouter";
import Flash from "./components/flash/Flash";

import { AppContext } from "./context";

import './App.css';


function App() {

  const [jwtToken, setJwtToken] = useState(null);
  const [user, setUser] = useState(null);

  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);

  const [flashRedirect, setFlashRedirect] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () => {

      if (localStorage.getItem('jwtToken') && (localStorage.getItem('user'))) {

        setJwtToken(localStorage.getItem('jwtToken'));

        setUser(JSON.parse(localStorage.getItem('user')));

      }

      window.addEventListener('keydown', (event) => {

        if (event.key === 'Escape') {
          setIsPopupOpen(false);
        }

      });

      setIsLoading(false);

    }, []);

  return (

    <AppContext.Provider value={{
      jwtToken, setJwtToken,
      user, setUser,
      flashMessage, setFlashMessage,
      flashMessageType, setFlashMessageType,
      flashRedirect, setFlashRedirect,
      isPopupOpen, setIsPopupOpen,
      isLoading
    }}>

      <BrowserRouter>

        <Navbar />

        {flashMessage && <Flash message={flashMessage} type={flashMessageType} />}

        <AppRouter />

      </BrowserRouter>

    </AppContext.Provider>
    
  );

}

export default App;
