import { Link, useLocation } from 'react-router-dom';

import classes from './Sidebar.module.css';

import CONFIG from '../../config';


const Sidebar = function() {

    const location = useLocation();

    const activeSidebarLinkClasses = [classes.sidebar__link, classes.sidebar__link__active].join(' ');

    return (

        <div className={classes.sidebar}>

            <div className={location.pathname === `${CONFIG.application_slug}/profile` ? activeSidebarLinkClasses : classes.sidebar__link}>
                <Link to={`${CONFIG.application_slug}/profile`}> Profile </Link>
            </div>

            <div className={location.pathname === `${CONFIG.application_slug}/scenarios` ? activeSidebarLinkClasses : classes.sidebar__link}>
                <Link to={`${CONFIG.application_slug}/scenarios`}> Scenarios </Link>
            </div>

        </div>

    )

};

export default Sidebar;
