import axios from "axios";

import CONFIG from "../config";


export default class ScenarioService {

    static async scenarios(jwtToken, user) {

        try {

            const url = `${CONFIG.backend_url}/scenario/scenarios`;

            const response = await axios.post(url, {user}, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });

            return response.data;

        } catch (error) {
            
            if (error.response) {
                return error.response.data;
            } else {
                throw error;
            };

        };

    };

    static async scenario(jwtToken, user, scenario_id) {

        try {

            const url = `${CONFIG.backend_url}/scenario/scenarios/${scenario_id}`;

            const response = await axios.post(url, {user}, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });

            return response.data;

        } catch (error) {

            if (error.response) {
                return error.response.data;
            } else {
                throw error;
            }

        };

    };

    static async createScenario(jwtToken, user, scenario) {

        try {

            const url = `${CONFIG.backend_url}/scenario/scenarios/new`;

            const response = await axios.post(url, {user, scenario}, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });

            return response.data;

        } catch (error) {
            
            if (error.response) {
                return error.response.data;
            } else {
                throw error;
            };

        };

    };

    static async editScenario(jwtToken, user, scenario_id, scenario) {

        try {

            const url = `${CONFIG.backend_url}/scenario/scenarios/${scenario_id}/edit`;

            const response = await axios.put(url, {user, scenario}, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });

            return response.data;

        } catch (error) {
            
            if (error.response) {
                return error.response.data;
            } else {
                throw error;
            };

        };

    };

    static async enableScenario(jwtToken, user, scenario_id) {

        try {

            const url = `${CONFIG.backend_url}/scenario/scenarios/${scenario_id}/enable`;

            const response = await axios.put(url, {user}, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });

            return response.data;

        } catch (error) {
            
            if (error.response) {
                return error.response.data;
            } else {
                throw error;
            };

        };

    };

    static async disableScenario(jwtToken, user, scenario_id) {

        try {

            const url = `${CONFIG.backend_url}/scenario/scenarios/${scenario_id}/disable`;

            const response = await axios.put(url, {user}, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });

            return response.data;

        } catch (error) {
            
            if (error.response) {
                return error.response.data;
            } else {
                throw error;
            };

        };

    };

    static async deleteScenario(jwtToken, user, scenario_id) {

        try {

            const url = `${CONFIG.backend_url}/scenario/scenarios/${scenario_id}/delete`;

            const response = await axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                },
                data: {
                    user
                }
            });

            return response.data;

        } catch (error) {
            
            if (error.response) {
                return error.response.data;
            } else {
                throw error;
            };

        };

    };

};
