import { useEffect, useContext } from "react";

import { AppContext } from "../context";


export const useFlash = () => {

    const { setFlashMessage, setFlashMessageType, flashRedirect, setFlashRedirect } = useContext(AppContext);

    useEffect(
        () => {

            if (flashRedirect) {
                
                setFlashRedirect(false);
            
            } else {

                setFlashMessage(null);
                setFlashMessageType(null);

            };

        }, []);

};
