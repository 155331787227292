import classes from './ProfileForm.module.css';


const ProfileForm = function ({children, ...props}) {

  return (
    <form className={classes.profile__form} {...props}> {children} </form>
  );

};

export default ProfileForm;
