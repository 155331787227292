import axios from "axios";

import CONFIG from "../config";


export default class RegAuthService {

    static async login(user) {

        try {

            const url = `${CONFIG.backend_url}/regauth/login`;

            const response = await axios.post(url, user);

            return response.data;

        } catch (error) {
            
            if (error.response) {
                return error.response.data;
            } else {
                throw error;
            };

        };

    };

    static async register(user) {

        try {

            const url = `${CONFIG.backend_url}/regauth/register`;

            const response = await axios.post(url, user);

            return response.data;

        } catch (error) {
            
            if (error.response) {
                return error.response.data;
            } else {
                throw error;
            };

        };

    };

};
