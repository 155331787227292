import * as go from "gojs";

import time_trigger from "../assets/img/node/time_trigger.png";
import webhook from "../assets/img/node/webhook.png";
import condition from "../assets/img/node/condition.png";
import make_http_request from "../assets/img/node/make_http_request.png";
import send_message from "../assets/img/node/send_message.png";
import send_email from "../assets/img/node/send_email.png";
import openweathermap from "../assets/img/node/openweathermap.png";
import server_monitor from "../assets/img/node/server_monitor.png";
import end from "../assets/img/node/end.png";


export function init($, setIsNodePopupsOpen, setPopupCallback, setScenarioDiagram, setPopupCurrentNodeId) {

    function makePort(name, spot, output, input) {

        return $(go.Shape, "Circle",
          {
            fill: null,
            stroke: null,
            desiredSize: new go.Size(7, 7),
            alignment: spot,
            alignmentFocus: spot,
            portId: name,
            fromSpot: spot, toSpot: spot,
            fromLinkable: output, toLinkable: input,
            cursor: "pointer"
          });
      }

    function showPorts(node, show) {
        node.ports.each(port => {
          if (port.portId !== "") {
            port.fill = show ? "rgba(0,0,0,.7)" : null;
          }
        });
    }

    const TriggerTemplate = $(go.Node, "Auto", {},
        $(go.Shape, "Circle", {
            stroke: null,
        }, new go.Binding("fill", "background")),
        $(go.Picture, {
            margin: 8,
            width: 40,
            height: 40
        },
        new go.Binding("source", "image")),
        new go.Binding("click", "click"),
        new go.Binding("location", "location", go.Point.parse).makeTwoWay(go.Point.stringify),
        makePort("B", go.Spot.Bottom, true, false),
        {
            mouseEnter: (e, node) => showPorts(node, true),
            mouseLeave: (e, node) => showPorts(node, false)
        }
    );

    const ConditionTemplate = $(go.Node, "Auto", {},
        $(go.Shape, "Diamond", {
            fill: "#38f0e8",
            stroke: null,
            width: 80,
            height: 80
        }),
        $(go.Picture, {
            margin: 8,
            width: 40,
            height: 40,
            source: condition
        }),
        new go.Binding("click", "click"),
        new go.Binding("location", "location", go.Point.parse).makeTwoWay(go.Point.stringify),
        makePort("T", go.Spot.Top, false, true),
        makePort("L", go.Spot.Left, true, false),
        makePort("R", go.Spot.Right, true, false),
        {
            mouseEnter: (e, node) => showPorts(node, true),
            mouseLeave: (e, node) => showPorts(node, false)
        }
    );

    const ActionTemplate = $(go.Node, "Auto", {},
        $(go.Shape, "RoundedRectangle", {
            fill: "#ff3c00",
            stroke: null,
            width: 80,
            height: 80
        }, new go.Binding("fill", "background")
        ),
        $(go.Picture, {
            margin: 8,
            width: 40,
            height: 40
        },
        new go.Binding("source", "image")),
        new go.Binding("click", "click"),
        new go.Binding("location", "location", go.Point.parse).makeTwoWay(go.Point.stringify),
        makePort("T", go.Spot.Top, false, true),
        makePort("B", go.Spot.Bottom, true, false),
        {
            mouseEnter: (e, node) => showPorts(node, true),
            mouseLeave: (e, node) => showPorts(node, false)
        }
    );

    const EndTemplate = $(go.Node, "Auto", {},
        $(go.Shape, "Circle", {
            fill: "#ff3c00",
            stroke: null,
        }),
        $(go.Picture, {
            margin: 8,
            width: 40,
            height: 40,
            source: end
        },
        new go.Binding("source", "image")),
        new go.Binding("location", "location", go.Point.parse).makeTwoWay(go.Point.stringify),
        makePort("T", go.Spot.Top, false, true),
        {
            mouseEnter: (e, node) => showPorts(node, true),
            mouseLeave: (e, node) => showPorts(node, false)
        }
    );

    const scenarioDiagram = $(go.Diagram, "diagram", {
        "undoManager.isEnabled": true,
        "linkingTool.portGravity": 20
    });

    scenarioDiagram.nodeTemplateMap.add("trigger", TriggerTemplate);
    scenarioDiagram.nodeTemplateMap.add("condition", ConditionTemplate);
    scenarioDiagram.nodeTemplateMap.add("action", ActionTemplate);
    scenarioDiagram.nodeTemplateMap.add("end", EndTemplate);

    scenarioDiagram.linkTemplate = $(go.Link,
        $(go.Shape),
        $(go.Shape, { toArrow: "Standard" }),
        $(go.TextBlock,
            new go.Binding("text", "label"),
            {
              stroke: "black",
              segmentOffset: new go.Point(0, -10)
            }
        )
    );

    const scenarioPalette = $(go.Palette, "palette", {
        maxSelectionCount: 1,
        nodeTemplateMap: scenarioDiagram.nodeTemplateMap,
        padding: 70,
        layout: $(go.GridLayout, {
            cellSize: new go.Size(1, 1),
            spacing: new go.Size(20, 20),
            wrappingColumn: 3
        }),
        model: new go.GraphLinksModel(
            [
                {
                    category: "trigger",
                    type: "time_trigger",
                    image: time_trigger,
                    background: "#00bfff"
                },
                {
                    category: "trigger",
                    type: "webhook",
                    image: webhook,
                    background: "#ff5858"
                },
                {
                    category: "condition",
                    type: "condition"
                },
                {
                    category: "action",
                    type: "make_http_request",
                    image: make_http_request,
                    background: "#a9c9f0"
                },
                {
                    category: "action",
                    type: "send_message",
                    image: send_message,
                    background: "#58eafa"
                },
                {
                    category: "action",
                    type: "send_email",
                    image: send_email,
                    background: "#f0a9f0"
                },
                {
                    category: "action",
                    type: "openweathermap",
                    image: openweathermap,
                    background: "#fab8b8"
                },
                {
                    category: "action",
                    type: "server_monitor",
                    image: server_monitor,
                    background: "#b8b8ff"
                },
                {
                    category: "end",
                    type: "end"
                }
            ]
        )
    });

    scenarioDiagram.addDiagramListener("ExternalObjectsDropped", function(e) {

        const newNodes = e.subject;

        newNodes.each(function(node) {

            let nodeId = crypto.randomUUID();

            let nodeTypes = ["time_trigger", "webhook", "condition", "make_http_request", "send_message", "send_email", "openweathermap", "end"]

            let nodeType = node.data.type;

            scenarioDiagram.model.setDataProperty(node.data, "key", nodeId);

            scenarioDiagram.model.setDataProperty(node.data, "click", function(e, obj) {

                if (e.clickCount === 2) {

                    let isNodePopupsOpen = {};

                    nodeTypes.forEach(nodeType_ => {
                        isNodePopupsOpen[nodeType_] = nodeType_ === nodeType;
                    });

                    setIsNodePopupsOpen(isNodePopupsOpen);

                    setPopupCurrentNodeId(nodeId);

                    let popupCallback = function(nodeParameters) {

                        Object.keys(nodeParameters).forEach(nodeParameter => {
                            scenarioDiagram.model.setDataProperty(node.data, nodeParameter, nodeParameters[nodeParameter]);
                        });

                        return node.data;

                    };

                    setPopupCallback({callback: popupCallback});

                }

            });

        });

    });

    scenarioDiagram.addDiagramListener("LinkDrawn", function(e) {

        let link = e.subject;

        let node = link.fromPort.data;

        if (node.category === 'condition') {

            let existingLinkCondition = null;

            for (let i = 0; i < scenarioDiagram.model.linkDataArray.length; i++) {
                if (scenarioDiagram.model.linkDataArray[i].from === node.key && scenarioDiagram.model.linkDataArray[i].condition !== undefined) {
                    existingLinkCondition = scenarioDiagram.model.linkDataArray[i].condition;
                }
            }

            if (existingLinkCondition !== null) {

                let linkLabel = existingLinkCondition ? "No" : "Yes";

                scenarioDiagram.model.setDataProperty(link.data, "label", linkLabel);

                link.data.condition = !existingLinkCondition;

            } else {

                scenarioDiagram.model.setDataProperty(link.data, "label", "Yes");

                link.data.condition = true;

            }

            scenarioDiagram.requestUpdate();

        } else {
            link.data.condition = true;
        }

    });

    setScenarioDiagram(scenarioDiagram);

}

export function initExisting($, setIsNodePopupsOpen, setPopupCallback, setScenarioDiagram, setPopupCurrentNodeId, nodes, links) {

    function makePort(name, spot, output, input) {

        return $(go.Shape, "Circle",
          {
            fill: null,
            stroke: null,
            desiredSize: new go.Size(7, 7),
            alignment: spot,
            alignmentFocus: spot,
            portId: name,
            fromSpot: spot, toSpot: spot,
            fromLinkable: output, toLinkable: input,
            cursor: "pointer"
          });
      }

    function showPorts(node, show) {
        node.ports.each(port => {
          if (port.portId !== "") {
            port.fill = show ? "rgba(0,0,0,.7)" : null;
          }
        });
    }

    const TriggerTemplate = $(go.Node, "Auto", {},
        $(go.Shape, "Circle", {
            stroke: null,
        }, new go.Binding("fill", "background")),
        $(go.Picture, {
            margin: 8,
            width: 40,
            height: 40
        },
        new go.Binding("source", "image")),
        new go.Binding("click", "click"),
        new go.Binding("location", "location", go.Point.parse).makeTwoWay(go.Point.stringify),
        makePort("B", go.Spot.Bottom, true, false),
        {
            mouseEnter: (e, node) => showPorts(node, true),
            mouseLeave: (e, node) => showPorts(node, false)
        }
    );

    const ConditionTemplate = $(go.Node, "Auto", {},
        $(go.Shape, "Diamond", {
            fill: "#38f0e8",
            stroke: null,
            width: 80,
            height: 80
        }),
        $(go.Picture, {
            margin: 8,
            width: 40,
            height: 40,
            source: condition
        }),
        new go.Binding("click", "click"),
        new go.Binding("location", "location", go.Point.parse).makeTwoWay(go.Point.stringify),
        makePort("T", go.Spot.Top, false, true),
        makePort("L", go.Spot.Left, true, false),
        makePort("R", go.Spot.Right, true, false),
        {
            mouseEnter: (e, node) => showPorts(node, true),
            mouseLeave: (e, node) => showPorts(node, false)
        }
    );

    const ActionTemplate = $(go.Node, "Auto", {},
        $(go.Shape, "RoundedRectangle", {
            fill: "#ff3c00",
            stroke: null,
            width: 80,
            height: 80
        }, new go.Binding("fill", "background")
        ),
        $(go.Picture, {
            margin: 8,
            width: 40,
            height: 40
        },
        new go.Binding("source", "image")),
        new go.Binding("click", "click"),
        new go.Binding("location", "location", go.Point.parse).makeTwoWay(go.Point.stringify),
        makePort("T", go.Spot.Top, false, true),
        makePort("B", go.Spot.Bottom, true, false),
        {
            mouseEnter: (e, node) => showPorts(node, true),
            mouseLeave: (e, node) => showPorts(node, false)
        }
    );

    const EndTemplate = $(go.Node, "Auto", {},
        $(go.Shape, "Circle", {
            fill: "#ff3c00",
            stroke: null,
        }),
        $(go.Picture, {
            margin: 8,
            width: 40,
            height: 40,
            source: end
        },
        new go.Binding("source", "image")),
        new go.Binding("location", "location", go.Point.parse).makeTwoWay(go.Point.stringify),
        makePort("T", go.Spot.Top, false, true),
        {
            mouseEnter: (e, node) => showPorts(node, true),
            mouseLeave: (e, node) => showPorts(node, false)
        }
    );

    const scenarioDiagram = $(go.Diagram, "diagram", {
        "undoManager.isEnabled": true,
        "linkingTool.portGravity": 20
    });

    scenarioDiagram.nodeTemplateMap.add("trigger", TriggerTemplate);
    scenarioDiagram.nodeTemplateMap.add("condition", ConditionTemplate);
    scenarioDiagram.nodeTemplateMap.add("action", ActionTemplate);
    scenarioDiagram.nodeTemplateMap.add("end", EndTemplate);

    scenarioDiagram.linkTemplate = $(go.Link,
        $(go.Shape),
        $(go.Shape, { toArrow: "Standard" }),
        $(go.TextBlock,
            new go.Binding("text", "label"),
            {
              stroke: "black",
              segmentOffset: new go.Point(0, -10)
            }
        )
    );

    const scenarioPalette = $(go.Palette, "palette", {
        maxSelectionCount: 1,
        nodeTemplateMap: scenarioDiagram.nodeTemplateMap,
        padding: 70,
        layout: $(go.GridLayout, {
            cellSize: new go.Size(1, 1),
            spacing: new go.Size(20, 20),
            wrappingColumn: 3
        }),
        model: new go.GraphLinksModel(
            [
                {
                    category: "trigger",
                    type: "time_trigger",
                    image: time_trigger,
                    background: "#00bfff"
                },
                {
                    category: "trigger",
                    type: "webhook",
                    image: webhook,
                    background: "#ff5858"
                },
                {
                    category: "condition",
                    type: "condition"
                },
                {
                    category: "action",
                    type: "make_http_request",
                    image: make_http_request,
                    background: "#a9c9f0"
                },
                {
                    category: "action",
                    type: "send_message",
                    image: send_message,
                    background: "#58eafa"
                },
                {
                    category: "action",
                    type: "send_email",
                    image: send_email,
                    background: "#f0a9f0"
                },
                {
                    category: "action",
                    type: "openweathermap",
                    image: openweathermap,
                    background: "#fab8b8"
                },
                {
                    category: "action",
                    type: "server_monitor",
                    image: server_monitor,
                    background: "#b8b8ff"
                },
                {
                    category: "end",
                    type: "end"
                }
            ]
        )
    });

    let nodesWithClickListeners = [];

    for (let i = 0; i < nodes.length; i++) {

        let node = nodes[i];

        node.click = function(e, obj) {

            if (e.clickCount === 2) {

                const nodeTypes = ["time_trigger", "webhook", "condition", "make_http_request", "send_message", "send_email", "openweathermap", "end"];

                let isNodePopupsOpen = {};

                nodeTypes.forEach(nodeType_ => {
                    isNodePopupsOpen[nodeType_] = nodeType_ === node.type;
                });

                setIsNodePopupsOpen(isNodePopupsOpen);

                setPopupCurrentNodeId(node.key);

                let popupCallback = function(nodeParameters) {

                    Object.keys(nodeParameters).forEach(nodeParameter => {
                        node[nodeParameter] = nodeParameters[nodeParameter];
                    });

                    return node;

                };

                setPopupCallback({callback: popupCallback});

            }

        };

        nodesWithClickListeners.push(node);

    }

    scenarioDiagram.model = new go.GraphLinksModel(nodesWithClickListeners, links);

    scenarioDiagram.addDiagramListener("ExternalObjectsDropped", function(e) {

        const newNodes = e.subject;

        newNodes.each(function(node) {

            let nodeId = crypto.randomUUID();

            let nodeTypes = ["time_trigger", "webhook", "condition", "make_http_request", "send_message", "send_email", "openweathermap", "end"]

            let nodeType = node.data.type;

            scenarioDiagram.model.setDataProperty(node.data, "key", nodeId);

            scenarioDiagram.model.setDataProperty(node.data, "click", function(e, obj) {

                if (e.clickCount === 2) {

                    let isNodePopupsOpen = {};

                    nodeTypes.forEach(nodeType_ => {
                        isNodePopupsOpen[nodeType_] = nodeType_ === nodeType;
                    });

                    setIsNodePopupsOpen(isNodePopupsOpen);

                    setPopupCurrentNodeId(nodeId);

                    let popupCallback = function(nodeParameters) {

                        Object.keys(nodeParameters).forEach(nodeParameter => {
                            scenarioDiagram.model.setDataProperty(node.data, nodeParameter, nodeParameters[nodeParameter]);
                        });

                        return node.data;

                    };

                    setPopupCallback({callback: popupCallback});

                }

            });

        });

    });

    scenarioDiagram.addDiagramListener("LinkDrawn", function(e) {

        let link = e.subject;

        let node = link.fromPort.data;

        if (node.category === 'condition') {

            let existingLinkCondition = null;

            for (let i = 0; i < scenarioDiagram.model.linkDataArray.length; i++) {
                if (scenarioDiagram.model.linkDataArray[i].from === node.key && scenarioDiagram.model.linkDataArray[i].condition !== undefined) {
                    existingLinkCondition = scenarioDiagram.model.linkDataArray[i].condition;
                }
            }

            if (existingLinkCondition !== null) {

                let linkLabel = existingLinkCondition ? "No" : "Yes";

                scenarioDiagram.model.setDataProperty(link.data, "label", linkLabel);

                link.data.condition = !existingLinkCondition;

            } else {

                scenarioDiagram.model.setDataProperty(link.data, "label", "Yes");

                link.data.condition = true;

            }

            scenarioDiagram.requestUpdate();

        } else {
            link.data.condition = true;
        }

    });

    setScenarioDiagram(scenarioDiagram);

}
