import classes from './ScenarioCard.module.css';


const ScenarioCard = function(props) {
    
    const ScenarioCardClasses = [classes.scenario__card, classes[props.status]].join(' ');

    return (

        <div className={ScenarioCardClasses}>

            <div className={classes.scenario} onClick={props.onClick}>

                <p className={classes.scenario__title}> {props.name} </p>
                <p className={classes.scenario__description}> {props.description} </p>

                {('last_run' in props && props.last_run !== null) ?
                    <p className={classes.scenario__last__run}> Last run: {props.last_run} </p>
                :
                    <p className={classes.scenario__last__run}> Never run yet </p>
                }

            </div>

            <div className={classes.scenario__active__switch}>

                <label className={classes.switch}> 
                    <input type="checkbox" className={classes.switch__input} onChange={(event) => props.onCheckbockChangeCallback(props.id, event.target.checked)} defaultChecked={props.active} />
                    <span className={[classes.switch__slider, classes.switch__round].join(' ')}> </span>
                </label>

            </div>

        </div>

    );

};

export default ScenarioCard;
